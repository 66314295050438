<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="item.title"
            :error-messages="errors['title']"
            :label="formMixin_getRequiredFieldLabel($t('general.title'))"
            @input="formMixin_clearErrors('title')"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="item.link"
            :error-messages="errors['link']"
            :label="formMixin_getRequiredFieldLabel($t('general.url'))"
            @input="formMixin_clearErrors('link')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          {{ $t('profile.general.icon_choose_from') }}
          <a :href="iconsLibraryUrl" target="_blank" class="link">{{ iconsLibraryUrl }}</a
          >,
          {{ $t('profile.general.copy_icon_text') }}
        </v-col>

        <v-col cols="12" sm="6" class="d-flex align-center overflow-x-hidden">
          <v-text-field
            v-model="item.icon"
            :error-messages="errors['icon']"
            :label="formMixin_getRequiredFieldLabel($t('general.icon'))"
            @input="formMixin_clearErrors('icon')"
          />
          <v-icon class="ml-2" style="max-width: 32px">{{ item.icon }}</v-icon>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <span v-if="!item.id" class="text-subtitle-2 ml-3">
        * {{ $t('general.must_be_filled') }}
      </span>

      <v-spacer />

      <v-btn text @click="$emit('cancel')">
        {{ $t('general.controls.cancel') }}
      </v-btn>

      <v-btn
        :disabled="disabled"
        :loading="disabled"
        color="primary"
        text
        @click="$emit('save', item)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';

export default {
  name: 'ShortcutForm',

  mixins: [formMixin],

  props: {
    shortcut: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
      iconsLibraryUrl: 'https://fonts.google.com/icons',
    };
  },

  computed: {
    formTitle() {
      return !this.item.id
        ? this.$t('profile.modal_titles.new_link')
        : this.$t('profile.modal_titles.edit_link');
    },
  },

  created() {
    this.item = clone(this.shortcut);
  },
};
</script>
