<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ShortcutForm
      :shortcut="newShortcut"
      :errors="shortcutValidationErrors"
      :disabled="$store.getters.loading['post:api/shortcut']"
      @clear:errors="CLEAR_SHORTCUT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ShortcutForm from '@/components/forms/ShortcutForm';

export default {
  name: 'CreateShortcut',

  components: { ShortcutForm },

  computed: {
    ...mapState('shortcuts', ['newShortcut', 'shortcutValidationErrors']),
  },

  created() {
    this.SET_SHORTCUT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('shortcuts', ['storeShortcut']),
    ...mapMutations('shortcuts', [
      'SET_SHORTCUT_VALIDATION_ERRORS',
      'CLEAR_SHORTCUT_VALIDATION_ERRORS',
    ]),

    onSave(shortcut) {
      this.storeShortcut(shortcut).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: 'profile' });
    },
  },
};
</script>
